<script setup lang="ts">
import { useStorage } from '@vueuse/core';

const isSidemenuOpen = useStorage('isSidemenuOpen', true);
</script>

<template>
  <aside
    class="group relative min-h-screen bg-white px-4 py-9 shadow-sm transition-all duration-300 ease-linear"
    :class="{
      'w-[183px] min-w-[231px]': isSidemenuOpen,
      'w-[67px] min-w-[67px]': !isSidemenuOpen,
    }"
  >
    <button
      class="absolute right-0 flex h-10 w-10 translate-x-1/2 -rotate-90 scale-75 items-center justify-center rounded-full border bg-white p-2 opacity-0 transition-all duration-200 ease-out group-hover:rotate-0 group-hover:scale-100 group-hover:opacity-100"
      @click="isSidemenuOpen = !isSidemenuOpen"
    >
      <i
        class="pi pi-step-backward text-lg text-gray-iron-900 transition-transform duration-300"
        :class="{
          'rotate-180': !isSidemenuOpen,
        }"
      />
    </button>

    <NuxtLink to="/">
      <AppLogo
        class="mb-8 ml-2.5 transition-opacity duration-700 ease-out"
        :class="{
          'w-0 opacity-0': !isSidemenuOpen,
        }"
      />
    </NuxtLink>

    <ContentNavigation class="sticky top-8 overflow-hidden" />
  </aside>
</template>
