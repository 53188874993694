<script setup lang="ts">
const avatarLabel = computed(() => {
  return getUserInitials('Damian Kubisiak');
});
</script>

<template>
  <PrimeAvatar
    class="text-paper-elevation-0 shrink-0 font-normal"
    :label="avatarLabel"
    size="large"
    shape="circle"
    :style="`background-color: ${getAvatarColor('Damian Kubisiak')}`"
  />
</template>
