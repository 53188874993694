<script setup lang="ts">
const op = ref();
</script>

<template>
  <PrimeButton
    class="shrink-0"
    text
    severity="secondary"
    @click="(event: MouseEvent) => op.toggle(event)"
  >
    <PrimeOverlayBadge value="2" severity="danger">
      <i class="pi pi-bell text-surface-900 !text-2xl" />
    </PrimeOverlayBadge>
  </PrimeButton>

  <PrimePopover class="max-h-[60vh] overflow-y-auto md:max-h-[80vh]" ref="op">
    <div v-auto-animate class="w-full max-w-[456px] space-y-3">
      <div class="flex items-center justify-between gap-4">
        <h2 class="text-bluegray-900 text-lg font-semibold">Powiadomienia</h2>
        <PrimeButton rounded outlined text>
          <i class="pi pi-check-square text-sm" />
          Oznacz wszystkie jako przeczytane
        </PrimeButton>
      </div>

      <ul v-auto-animate class="divide-y-[1px]">
        <li>
          <NotificationBox />
        </li>
      </ul>

      <p class="text-bluegray-900">Brak powiadomień</p>
    </div>
  </PrimePopover>
</template>
