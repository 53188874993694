<script setup lang="ts"></script>

<template>
  <header class="flex items-center gap-4">
    <h1 class="mr-auto text-2xl font-medium text-gray-iron-900">
      {{ $route.meta.title }}
    </h1>

    <NotificationMenu />

    <UserMenu />
  </header>
</template>
