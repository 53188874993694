<script setup lang="ts">
const op = ref();

// const props = defineProps<{}>();
</script>

<template>
  <article
    class="flex items-start gap-3 rounded-md px-2 py-3"
    :class="{
      'my-3 border border-solid border-blue-100 bg-blue-50': true,
    }"
  >
    <div
      class="flex aspect-square w-6 shrink-0 items-center justify-center rounded-full bg-yellow-500"
    >
      <i class="pi pi-star text-primary-inverse text-xs" />
    </div>

    <div
      class="text-bluegray-500 flex-1 space-y-1"
      :class="{
        'cursor-pointer hover:underline': true,
      }"
    >
      <div class="text-bluegray-900 text-sm">title</div>

      <div class="text-bluegray-500 text-xs">date</div>
    </div>

    <PrimeButton
      text
      severity="secondary"
      @click="(event: MouseEvent) => op.toggle(event)"
    >
      <i class="pi pi-ellipsis-v text-bluegray-800 text-base" />
    </PrimeButton>

    <PrimePopover class="before:border-0 after:border-0" ref="op">
      <div class="flex w-60 flex-col gap-1">
        <PrimeButton
          class="!text-global-textColor w-full !justify-start !font-normal"
          text
          severity="secondary"
        >
          <i class="pi pi-check-square text-sm" />
          Oznacz jako przeczytane
        </PrimeButton>

        <PrimeButton
          class="!text-global-textColor w-full !justify-start !font-normal"
          text
          severity="secondary"
        >
          <i class="pi pi-trash text-sm" />
          Usuń z listy
        </PrimeButton>
      </div>
    </PrimePopover>
  </article>
</template>
